@import '../../../styles/variables.scss';
.SummaryRow {
  font-weight: bolder;
  td {
    background: #ffa36b;
    color: $color_black;
  }
}

.SummaryRow + .SummaryRow {
  td {
    background: #ee7b4f;
  }
}
