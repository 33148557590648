@import './variables.scss';
@import './mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

html {
  font-size: 16px;
}

html,
body,
input {
  margin: 0;
  font-family: Source Sans Pro, Arial;
  line-height: 1.35;
}
body,
input {
  background: $primary_background;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins, Arial;
  word-break: break-word;
  color: $new_secondary_color_dark_blue;
}
.flex-container {
  display: flex;
  justify-content: center;
  max-width: 1240px;
  margin: 0 1rem;
}
.top-align {
  justify-content: start;
}

.flex-column {
  flex-direction: column;
}

.block {
  display: block;
}

[type='button']:not(:disabled) {
  @include medium-text;
  color: #fff;
  font-family: Source Sans Pro, Arial;
}

a {
  color: $new_primary_color_blue !important;
  text-decoration: none !important;
  background-color: transparent !important;
  border: 0;
}
a:hover {
  color: $new_secondary_color_dark_purple;
  text-decoration: underline;
}

.download-button {
  color: $new_primary_color_blue;
  text-decoration: none !important;
  background-color: transparent !important;

  margin: 0 !important;
  padding-left: 10px;
  border-radius: 7px;

  &:hover,
  &:active,
  &:focus {
    color: $new_primary_color_blue !important;
    background-color: rgba(255, 140, 67, 0.3) !important;
  }
}

div.static-container {
  a,
  .download-button {
    font-size: 18px;
  }
}

p,
span,
li {
  color: $color_black;
  font-family: Source Sans Pro, Arial;
  @include medium-text;
}
.App > .flex-container {
  min-height: calc(100vh - #{$footer-height} - 11rem);
}

#diku {
  background-color: #ffffff;
}

@media screen and (min-width: $tablet) {
  .flex-container {
    margin: 0 4rem;
  }
  html {
    font-size: 18px;
  }
}
@media screen and (min-width: $laptop) {
  .flex-container {
    margin: auto;
  }
}

.static-figure-description {
  p,
  span {
    font-size: 11px;
  }
}

.static-table-description {
  zoom: 50%;

  p,
  span {
    @include small-text;
  }
}

@media screen and (min-width: $large-tablet) {
  .static-table-description {
    zoom: inherit;
  }
}
