@mixin header-text {
  font-size: 2.8rem;
}
@mixin small-text {
  font-size: 0.8em;
}

@mixin medium-text {
  font-size: 1rem;
}
