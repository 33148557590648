@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
.app-footer {
  background: $new_primary_color_dark_orange;
  width: 100%;
  padding: 4rem 0 3rem 0;
}
.logo {
  width: 25%;
  margin-bottom: 4rem;
}
.text-field p,
.text-field a {
  color: #fff !important;
  font-family: Source Sans Pro, Arial;
  font-size: 16px;
  margin-bottom: 3rem;
}

.text-field a {
  margin-left: 0.5rem;
}

.text-field .privacy a,
.text-field .privacy a:hover {
  margin: 0;
  border-bottom: 3px solid #fff !important;
  padding-bottom: 0.3rem;
  text-decoration: none !important;
}

.text-field p.privacy {
  margin-bottom: 1rem;
}
