@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

img.diku-logo {
  max-width: 380px;
  padding: 2rem 0;
}

.medium-text {
  @include medium-text;
}
.title {
  @include header-text;
  font-weight: bold;
}
.blue {
  color: $new_primary_color_blue;
}

.bold {
  color: $new_primary_color_purple;
  font-weight: bold;
}

.metadata {
  max-width: 45rem;
  color: #2f2d2d;
}
.minus-margin {
  margin-left: 0;
}

@media screen and (min-width: $tablet) {
  .minus-margin {
    margin-left: -4rem;
  }
}
