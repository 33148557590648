$primary_background: rgba(255, 140, 67, 0.1);
$footer-height: 13rem;

// media queries
$laptop: '1300px';
$large-tablet: '992px';
$tablet: '768px';
$mobile: '576px';

/*NEW PROFILE FOR HKDIR*/

/*primary colors */

$new_primary_color_magenta: #e72f72;
$new_primary_color_dark_orange: #ea591d;
$new_primary_color_light_orange: #ff8c43;
$new_primary_color_yellow: #ffc948;
$new_primary_color_blue: #0025a0;
$new_primary_color_purple: #9b3699;
$new_primary_color_grey: #a2a4a5;

/*secondary colors*/

$new_secondary_color_dark_blue: #171b4e;
$new_secondary_color_dark_purple: #630879;
$new_secondary_color_dark_red: #a80037;

$color_black: #000000;
$color_white: #ffffff;
$button_active_color: $new_primary_color_light_orange;
