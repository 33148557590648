@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.CustomTable {
  table-layout: fixed;
  zoom: 50%;
  @include small-text;
  th,
  td {
    padding: 5px;
    border-bottom: 1px solid #ddd;
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-align: center;
  }

  th {
    background-color: $new_primary_color_blue;
    color: $color_white;
    height: 50%;
    z-index: 3000;
    vertical-align: middle;
  }
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(255, 140, 67, 0.15);
}

.table-bordered {
  border: 1px solid rgb(255, 220, 199);
}
.table-bordered > thead > tr > th {
  border: 1px solid rgb(255, 220, 199);
  overflow-wrap: break-word;
}
.table-bordered > tbody > tr > td {
  border: 1px solid rgb(255, 220, 199);
}

.link-container {
  display: grid;
  grid-template-columns: 1fr;
}

@media screen and (min-width: $large-tablet) {
  .CustomTable th,
  td {
    padding: 7px;
  }
  .CustomTable {
    zoom: inherit;
  }

  .link-container {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
  }
}

.open-table-new-tab {
  margin: 0 !important;
  padding-left: 10px;
  border-radius: 7px;
  line-height: 2rem;

  &:hover,
  &:active,
  &:focus {
    color: $new_primary_color_blue !important;
    background-color: rgba(255, 140, 67, 0.3) !important;
    outline: none;
  }
}
