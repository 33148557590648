@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
/*NEW PROFILE FOR HKDIR*/
/*primary colors */
/*secondary colors*/
html {
  font-size: 16px; }

html,
body,
input {
  margin: 0;
  font-family: Source Sans Pro, Arial;
  line-height: 1.35; }

body,
input {
  background: rgba(255, 140, 67, 0.1); }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins, Arial;
  word-break: break-word;
  color: #171b4e; }

.flex-container {
  display: flex;
  justify-content: center;
  max-width: 1240px;
  margin: 0 1rem; }

.top-align {
  justify-content: start; }

.flex-column {
  flex-direction: column; }

.block {
  display: block; }

[type='button']:not(:disabled) {
  font-size: 1rem;
  color: #fff;
  font-family: Source Sans Pro, Arial; }

a {
  color: #0025a0 !important;
  text-decoration: none !important;
  background-color: transparent !important;
  border: 0; }

a:hover {
  color: #630879;
  text-decoration: underline; }

.download-button {
  color: #0025a0;
  text-decoration: none !important;
  background-color: transparent !important;
  margin: 0 !important;
  padding-left: 10px;
  border-radius: 7px; }
  .download-button:hover, .download-button:active, .download-button:focus {
    color: #0025a0 !important;
    background-color: rgba(255, 140, 67, 0.3) !important; }

div.static-container a,
div.static-container .download-button {
  font-size: 18px; }

p,
span,
li {
  color: #000000;
  font-family: Source Sans Pro, Arial;
  font-size: 1rem; }

.App > .flex-container {
  min-height: calc(100vh - 13rem - 11rem); }

#diku {
  background-color: #ffffff; }

@media screen and (min-width: 768px) {
  .flex-container {
    margin: 0 4rem; }
  html {
    font-size: 18px; } }

@media screen and (min-width: 1300px) {
  .flex-container {
    margin: auto; } }

.static-figure-description p,
.static-figure-description span {
  font-size: 11px; }

.static-table-description {
  zoom: 50%; }
  .static-table-description p,
  .static-table-description span {
    font-size: 0.8em; }

@media screen and (min-width: 992px) {
  .static-table-description {
    zoom: inherit; } }

/*NEW PROFILE FOR HKDIR*/
/*primary colors */
/*secondary colors*/
.App {
  background: rgba(255, 140, 67, 0.1); }

/*NEW PROFILE FOR HKDIR*/
/*primary colors */
/*secondary colors*/
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #a80037;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  margin: auto;
  margin-top: 10em; }

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/*NEW PROFILE FOR HKDIR*/
/*primary colors */
/*secondary colors*/
img.diku-logo {
  max-width: 380px;
  padding: 2rem 0; }

.medium-text {
  font-size: 1rem; }

.title {
  font-size: 2.8rem;
  font-weight: bold; }

.blue {
  color: #0025a0; }

.bold {
  color: #9b3699;
  font-weight: bold; }

.metadata {
  max-width: 45rem;
  color: #2f2d2d; }

.minus-margin {
  margin-left: 0; }

@media screen and (min-width: 768px) {
  .minus-margin {
    margin-left: -4rem; } }

/*NEW PROFILE FOR HKDIR*/
/*primary colors */
/*secondary colors*/
.app-footer {
  background: #ea591d;
  width: 100%;
  padding: 4rem 0 3rem 0; }

.logo {
  width: 25%;
  margin-bottom: 4rem; }

.text-field p,
.text-field a {
  color: #fff !important;
  font-family: Source Sans Pro, Arial;
  font-size: 16px;
  margin-bottom: 3rem; }

.text-field a {
  margin-left: 0.5rem; }

.text-field .privacy a,
.text-field .privacy a:hover {
  margin: 0;
  border-bottom: 3px solid #fff !important;
  padding-bottom: 0.3rem;
  text-decoration: none !important; }

.text-field p.privacy {
  margin-bottom: 1rem; }

/*NEW PROFILE FOR HKDIR*/
/*primary colors */
/*secondary colors*/
.CustomTable {
  table-layout: fixed;
  zoom: 50%;
  font-size: 0.8em; }
  .CustomTable th,
  .CustomTable td {
    padding: 5px;
    border-bottom: 1px solid #ddd;
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-align: center; }
  .CustomTable th {
    background-color: #0025a0;
    color: #ffffff;
    height: 50%;
    z-index: 3000;
    vertical-align: middle; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff; }

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(255, 140, 67, 0.15); }

.table-bordered {
  border: 1px solid #ffdcc7; }

.table-bordered > thead > tr > th {
  border: 1px solid #ffdcc7;
  overflow-wrap: break-word; }

.table-bordered > tbody > tr > td {
  border: 1px solid #ffdcc7; }

.link-container {
  display: grid;
  grid-template-columns: 1fr; }

@media screen and (min-width: 992px) {
  .CustomTable th,
  td {
    padding: 7px; }
  .CustomTable {
    zoom: inherit; }
  .link-container {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr; } }

.open-table-new-tab {
  margin: 0 !important;
  padding-left: 10px;
  border-radius: 7px;
  line-height: 2rem; }
  .open-table-new-tab:hover, .open-table-new-tab:active, .open-table-new-tab:focus {
    color: #0025a0 !important;
    background-color: rgba(255, 140, 67, 0.3) !important;
    outline: none; }

/*NEW PROFILE FOR HKDIR*/
/*primary colors */
/*secondary colors*/
.SummaryRow {
  font-weight: bolder; }
  .SummaryRow td {
    background: #ffa36b;
    color: #000000; }

.SummaryRow + .SummaryRow td {
  background: #ee7b4f; }

ul {
  list-style-type: none;
  padding: 0; }

p.DataDescription {
  white-space: pre-wrap;
  text-align: left; }

